import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
			this.loading = '.js-loading';
			//this.bg = '.js-loading__bg';
			this.line = '.js-loading__line';
			this.lineInner = '.js-loading__line-inner';
			this.lineLeft = '.js-loading__line-left';
			this.lineRight = '.js-loading__line-right';
			this.boxWhite = '.js-loading__box-white';
			this.boxGradation = '.js-loading__box-gradation';

			this.scroll = '.js-scroll';
			this.heroTxt = '.js-hero-txt';

			this.line01 = '.js-line01';
			this.line02 = '.js-line02';
			this.line03 = '.js-line03';
			this.line04 = '.js-line04';
			this.line05 = '.js-line05';
			this.line06 = '.js-line06';
			this.line07 = '.js-line07';

			this.init();
    }

    init() {
			window.addEventListener('load', () => {
				setTimeout(() => {
					this.loadAnimation();
				}, 500);
			});
		}

	loadAnimation() {
		const loading = document.querySelector(this.loading);
		//const bg = document.querySelector(this.bg);
		const line = document.querySelector(this.line);
		const lineInner = document.querySelector(this.lineInner);
		const lineLeft = document.querySelector(this.lineLeft);
		const lineRight = document.querySelector(this.lineRight);
		const boxWhite = document.querySelector(this.boxWhite);
		const boxGradation = document.querySelector(this.boxGradation);
		const scroll = document.querySelector(this.scroll);
		const heroTxt = document.querySelector(this.heroTxt);

		const line01 = document.querySelector(this.line01);
		const line02 = document.querySelector(this.line02);
		const line03 = document.querySelector(this.line03);
		const line04 = document.querySelector(this.line04);
		const line05 = document.querySelector(this.line05);
		const line06 = document.querySelector(this.line06);
		const line07 = document.querySelector(this.line07);

		const windowW = window.innerWidth;
		let boxWidth;

		if (windowW < 1380) {
			boxWidth = '235px';
		} else {
			boxWidth = '21%';
		}
		if (heroTxt) {
			//ローディングがなくてもテキストアニメーション
			const txtAnimationTl = gsap.timeline({
				paused: true,
			});
			const lineAry = [line01, line02, line03, line04,line05,line06,line07];
			txtAnimationTl.add(() => {
				heroTxt.classList.add('is-active');
			}, '<')
				.to(scroll, {
					opacity: 1,
					duration: 0.25,
				}, '+=2')
				.add(() => {
					lineAry.forEach(element => {
						element.classList.add('is-active');
					});
					if (windowW > 1380) {
						line05.classList.remove('is-active');
						line06.classList.remove('is-active');
					}
				},0);

			//ローディングがある場合
			if (loading) {
				const loadingTl = gsap.timeline({
					duration: 0.2,
				});
				loadingTl.to(lineLeft, {
					width: '51%',
					duration: 0.5,
				})
				.to(lineRight, {
					width: '51%',
					duration: 0.5,
				}, '<')
				.to(line, {
					width: '32px',
					height: '32px',
				})
				.to(lineInner, {
					width: '16px',
					background: '#0038B5',
					duration: 0.3,
				})
				.to(lineLeft, {
					opacity: 0,
					duration: 0,
				}, '+=0.1')
				.to(lineRight, {
					opacity: 0,
					duration: 0,
				}, '<')
				.to(lineInner, {
					translate: '-50% -50%',
					rotate: '45deg',
					duration: 0.15,
				}, '+=0.275')
				.to(lineInner, {
					width: '500vh',
					height: '500vh',
					duration: 0.5,
				}, '+=0.2')
				.to(boxWhite, {
					width: '500vh',
					height: '500vh',
					duration: 0.5,
				}, '-=0.25')
				.to(boxGradation, {
					opacity: 1,
					duration: 0.1,
				}, '+=0.1')
				.to(boxGradation, {
					width: boxWidth,
					aspectRatio: '1/1',
					duration: 0.15,
					ease: 'power4.easeIn',
				}, '+=0.3')
				.to(loading, {
					opacity: 0,
					duration: 0.15,
				}, '+=0.5')
				.to(loading, {
					display: 'none',
				})
				.add(txtAnimationTl.play());
			} else {
				setTimeout(() => {
						txtAnimationTl.play();
				}, 100);
			}
		}
	}
}
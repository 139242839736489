import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
			this.fade = '.js-inview';
			this.init();
    }

    init() {
			const fadeItemAry = gsap.utils.toArray(this.fade);
			fadeItemAry.forEach(element => {
				ScrollTrigger.create({
					trigger: element,
					start: 'top 75%',
					end: 'bottom 75%',
					once: true,
					toggleClass: {
						targets: element,
						className: "is-active"
					},
				});
			});
		}
}
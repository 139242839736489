
export default class {
    constructor() {
        this.urlCopy = '.js-url-link';
        this.init();
    }
    init() {
        const url = document.querySelector(this.urlCopy);
        if (url) {
            url.addEventListener('click',() =>{
                const copyUrl = url.dataset.url;
                navigator.clipboard.writeText(copyUrl);
            });
        }
    }
}
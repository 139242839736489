
export default class {
    constructor() {
				this.heroTxt = '.js-hero-txt';
        this.init();
    }

    init() {
			const txtWrap = document.querySelector(this.heroTxt);
			if (txtWrap) {
				const nodes = [...txtWrap.childNodes];
				let decoTxtWrap = "";
				nodes.forEach(node => {
					if (node.nodeType === Node.TEXT_NODE) {
						let text = node.textContent.replace(/\r?\n/g, '');
						decoTxtWrap = decoTxtWrap + text.split('').reduce((acc, v) => {
							return acc + `<span>${v}</span>`;
						}, "");
					} else {
						decoTxtWrap = decoTxtWrap + node.outerHTML;
					}
				});
				txtWrap.innerHTML = decoTxtWrap;
			}
    }
}
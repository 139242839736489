export default class {
	constructor() {
			this.radioItem = '.js-radio-param';
			this.init();

			this.telInput = document.querySelector('.js-tel-input');
			this.tel();
	}
	init() {
		const radioWrap = document.querySelector(this.radioItem);
		if (radioWrap) {
			let url = new URL(window.location.href);
			let param = url.searchParams.get('job');
			const radioAry = document.querySelectorAll('.js-radio-param input[type="radio"][name="job"]');
			radioAry.forEach(element => {
				const itemValue = element.value;
				if (itemValue === param) {
					element.checked = true;
				}
			});
		}
	}

	tel() {
		const telInput = this.telInput;
		if (telInput) {
				telInput.setAttribute('inputmode','tel');
		}
	}
}